@import './variables';

body {
    #root-wrapper {
        .xm-grid-product {
            
            .cancel-off-png, .cancel-on-png, .star-half-png, .star-off-png, .star-on-png {
                color: $pink;
            }

            /// Product Tabs ///
            #product-tabs {
                ul.tabs {
                    background-color: #f5f5f5;
                    margin-bottom: 10px;

                    li {
                        a {
                            &.current {
                                color: $pink;
                                border-top: 2px solid $pink;
                            }
                        }
                    }
                }

                .tabs-panels {
                    li {
                        list-style: disc inside;

                        & > * {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}