@charset "UTF-8";
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
html body #root-wrapper header#header {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
html body #root-wrapper header#header + .main-container > .main.container {
  padding-top: 170px;
}
html body #root-wrapper header#header.floating .top-header-container {
  display: none;
}
html body #root-wrapper header#header .top-header-container {
  color: white;
  background-color: black;
  font-weight: 300;
  font-size: 14px;
}
html body #root-wrapper header#header .top-header-container .item-user-links .module-user-links.user-link-wrapper ul.links li a {
  color: white;
}
html body #root-wrapper header#header .top-header-container .module-switch-customer a {
  color: white;
}
html body #root-wrapper header#header .main-header-container {
  max-height: 50px;
  border-bottom: 1px solid black;
}
html body #root-wrapper header#header .main-header-container .logo-column {
  max-height: 50px;
}
html body #root-wrapper header#header .main-header-container .logo-column .header.logo-wrapper {
  max-height: 50px;
}
html body #root-wrapper header#header .main-header-container .logo-column .header.logo-wrapper .logo {
  max-width: 190px;
  top: 0;
  left: 26px;
  position: absolute;
  z-index: 100;
}
html body #root-wrapper header#header .main-header-container .logo-column .header.logo-wrapper .logo img {
  position: relative;
  top: -18px;
}
html body #root-wrapper header#header .main-header-container .nav-column {
  width: auto;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0.nav-item--home {
  display: none;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0 > a {
  color: black;
  font-size: 18px;
  font-weight: 300;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0 > a .caret {
  display: none;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0 .nav-panel--dropdown a span {
  text-transform: none;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0.mega .nav-panel--dropdown {
  background-color: white;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0.mega .nav-panel--dropdown .nav-panel-inner .module-sub-categories {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0.5%;
  padding-right: 0.5%;
  border-right: 1px solid black;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0.mega .nav-panel--dropdown .nav-panel-inner .module-sub-categories:last-of-type {
  border: none;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0.mega .nav-panel--dropdown .nav-panel-inner .module-sub-categories > div.nav-item a span {
  font-weight: bold;
  color: black;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0:not(.mega) .nav-panel--dropdown {
  padding: 0;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0:not(.mega) .nav-panel--dropdown li {
  padding: 10px 20px;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0:not(.mega) .nav-panel--dropdown li a {
  line-height: 24px;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0:not(.mega) .nav-panel--dropdown li a span {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0:not(.mega) .nav-panel--dropdown li a span.caret {
  border: none;
  color: black;
  opacity: 1;
  display: inline-block;
  margin: 0;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0:not(.mega) .nav-panel--dropdown li a span.caret::before {
  font-family: "fontAwesome";
  content: "";
  font-size: 30px;
}
html body #root-wrapper header#header .main-header-container .nav-column .nav-regular .nav-item.level0:not(.mega) .nav-panel--dropdown li a:hover span.caret {
  color: #937043;
}
html body #root-wrapper header#header .main-header-container .search-column {
  margin: 0;
}
html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper.flyout .form-search input#search.input-text {
  z-index: 101;
  color: transparent !important;
  font-weight: 600;
}
html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper.flyout .form-search input#search.input-text:hover, html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper.flyout .form-search input#search.input-text:active, html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper.flyout .form-search input#search.input-text:focus {
  color: black !important;
  width: 250px;
  background-color: white;
  z-index: 1001;
}
html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper.flyout .form-search input#search.input-text:hover + button, html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper.flyout .form-search input#search.input-text:active + button, html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper.flyout .form-search input#search.input-text:focus + button {
  z-index: 1002;
  pointer-events: none;
}
html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper .form-search input#search.input-text {
  margin-top: 7px;
  border-radius: 36px;
  box-shadow: 0 0.15em 0.65em 0 rgba(0, 0, 0, 0.25);
}
html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper .form-search button.button-search {
  top: 7px;
  line-height: 36px;
  width: 36px;
  height: 36px;
  box-shadow: -0.15em 0 0.65em 0 rgba(0, 0, 0, 0.25);
  background-color: #ffc944;
  border-radius: 50%;
}
html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper .form-search button.button-search .fa {
  color: black;
  padding: 0;
}
html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper .form-search button.button-search:hover {
  background-color: #937043;
}
html body #root-wrapper header#header .main-header-container .search-column .module-search.search-wrapper .form-search button.button-search:hover .fa {
  color: white;
}
html body #root-wrapper header#header .main-header-container .cart-column {
  width: auto;
  margin-left: 0;
  margin-right: 3%;
}
html body #root-wrapper header#header .main-header-container .cart-column #mini-cart {
  margin-top: 7px;
}
html body #root-wrapper header#header .main-header-container .cart-column #mini-cart .dropdown-toggle .feature-icon-hover span.fa.fa-shopping-cart {
  padding: 0;
  line-height: 36px;
  width: 36px;
  height: 36px;
  box-shadow: 0em 0.15em 0.65em 0em rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
}
html body #root-wrapper header#header .main-header-container .cart-column #mini-cart .dropdown-toggle .feature-icon-hover span.fa.fa-shopping-cart::before {
  left: 2px;
}
html body #root-wrapper header#header .main-header-container .cart-column #mini-cart .dropdown-toggle .feature-icon-hover span.fa.fa-shopping-cart ~ * {
  display: none;
}
html body #root-wrapper header#header .main-header-container .cart-column #mini-cart .dropdown-toggle .feature-icon-hover .caret {
  display: none;
}
@media (max-width: 959px) {
  html body #root-wrapper header#header + .main-container > .main.container {
    padding-top: 90px;
  }
}
html body #root-wrapper header#header .mobile-header-container {
  border-bottom: 1px solid #937043;
}
html body #root-wrapper header#header .mobile-header-container .grid12-12, html body #root-wrapper header#header .mobile-header-container .grid-full {
  overflow: hidden;
}
html body #root-wrapper header#header .mobile-header-container .module-header-multi {
  height: 42px;
}
html body #root-wrapper header#header .mobile-header-container .module-header-multi .item .fa {
  font-size: 18px !important;
  margin: 3px 0 0 0 !important;
  padding: 0 !important;
  line-height: 35px;
  width: 35px;
  height: 35px;
  box-shadow: 0em 0.15em 0.65em 0em rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
}
html body #root-wrapper header#header .mobile-header-container .module-header-multi .nav-logo {
  margin: 0;
}
html body #root-wrapper header#header .mobile-header-container .module-header-multi .nav-logo a.logo {
  width: 100px;
  position: absolute;
  top: 0;
  left: 20px;
}
html body #root-wrapper header#header .mobile-header-container .module-header-multi .nav-logo a.logo img {
  position: relative;
  top: -12px;
}
html body #root-wrapper header#header .mobile-header-container .module-header-multi a {
  color: black;
}
html.page-type-home body #root-wrapper header#header + .main-container > .main.container {
  padding-top: 94px;
}
html.page-type-home body #root-wrapper header#header.floating + .main-container > .main.container {
  padding-top: 51px;
}
@media (max-width: 959px) {
  html.page-type-home body #root-wrapper header#header + .main-container > .main.container {
    padding-top: 43px;
  }
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
body #root-wrapper .xm-grid-category {
  font-size: 14px;
}
body #root-wrapper .xm-grid-category .sidebar a {
  font-size: 16px;
  padding: 0;
}
body #root-wrapper .xm-grid-category .view-mode .active {
  background-position-y: -237px;
}
body #root-wrapper .xm-grid-category .products-grid {
  background-color: #fcfcfc;
}
body #root-wrapper .xm-grid-category .products-grid .item .module-category-product-listing {
  margin-bottom: -20px;
}
body #root-wrapper .xm-grid-category .products-grid .item .module-category-product-listing p[class*=price] {
  text-align: center;
}
body #root-wrapper .xm-grid-category .products-grid .item .module-category-product-listing input.qty {
  width: 25% !important;
  display: block;
  margin: 0 auto 15px auto;
  line-height: 24px;
}
body #root-wrapper .xm-grid-category .products-grid .item .module-category-product-listing button.add-cart-button {
  display: block;
  margin: auto !important;
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
body #root-wrapper .xm-grid-product .cancel-off-png, body #root-wrapper .xm-grid-product .cancel-on-png, body #root-wrapper .xm-grid-product .star-half-png, body #root-wrapper .xm-grid-product .star-off-png, body #root-wrapper .xm-grid-product .star-on-png {
  color: #e95095;
}
body #root-wrapper .xm-grid-product #product-tabs ul.tabs {
  background-color: #f5f5f5;
  margin-bottom: 10px;
}
body #root-wrapper .xm-grid-product #product-tabs ul.tabs li a.current {
  color: #e95095;
  border-top: 2px solid #e95095;
}
body #root-wrapper .xm-grid-product #product-tabs .tabs-panels li {
  list-style: disc inside;
}
body #root-wrapper .xm-grid-product #product-tabs .tabs-panels li > * {
  display: inline-block;
}

body #root-wrapper footer#footer {
  background-color: black;
  color: #fff;
  margin-top: 30px;
  box-shadow: 0em 0.15em 2em rgba(0, 0, 0, 0.15);
  border-top: 1px solid #937043;
  font-size: 16px;
}
body #root-wrapper footer#footer .footer-container2 > div {
  background-color: black;
  float: none;
  display: block;
  color: white;
}
body #root-wrapper footer#footer .footer-container2 > div .section {
  padding-top: 30px;
}
body #root-wrapper footer#footer .footer-container2 > div .section div[id*=column] {
  margin-bottom: 30px;
}
body #root-wrapper footer#footer .footer-container2 > div .section div[id*=column] a.logo {
  position: relative;
  top: -48px;
}
body #root-wrapper footer#footer .footer-container2 > div .section div[id*=column] h6.block-title.heading {
  color: white;
  font-size: 16px;
  font-weight: 500;
}
body #root-wrapper footer#footer .footer-container2 > div .section div[id*=column] a {
  color: white;
}
body #root-wrapper footer#footer .footer-container2 > div .section div[id*=column] ul li a {
  font-size: 16px;
}
body #root-wrapper footer#footer .footer-container2 > div .section div[id*=column] ul li a:hover {
  border: none;
  text-decoration: underline;
}
body #root-wrapper footer#footer .footer-container2 > div .section div[id*=column] .social-icons a {
  margin-left: 20px;
}
body #root-wrapper footer#footer .footer-container2 > div .section div[id*=column] .social-icons a i.fa {
  font-size: 20px;
}
body #root-wrapper footer#footer .footer-container2 .footer-bottom-container {
  /* display: none; */
}

body {
  font-weight: 300;
}
body #scroll-to-top {
  border: 3px solid black;
  color: black;
  opacity: 1;
  background-image: none;
  background-color: white;
}
body #scroll-to-top::after {
  content: "";
  font-size: 50px;
  font-family: fontAwesome;
  text-indent: 0;
  position: absolute;
  top: 12px;
  left: 9.5px;
}
body #scroll-to-top:hover {
  background-color: black;
  color: white;
  border-color: black;
}

/*# sourceMappingURL=general.css.map */
