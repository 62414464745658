/* Home */
.page-type-home .section-title {
    text-align: center;
    font-size: 3em;
    padding-right: 0!important;
    font-size: 3em;
    margin-top: 20px;
    letter-spacing: 1px;
}
.featured-cats img {
    max-height: 260px;
}
.featured-cats .grid12-2 {
    position: relative;
    height: 330px;
}
.featured-cats h2 {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.featured-cats h2 a {
    background-color: #96b462;
    border-radius: 30px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    display: block;
    line-height: 1em;
    padding: 10px 0;
    display: block;
    margin: auto;
    font-size: 15px;
}
.featured-cats .chocolate h2 a {
    background-color: #ffc944;
    color: #000;
}
.featured-cats .jordan-almonds h2 a {
    background-color: #2679c4;
}
.featured-cats .caramel h2 a {
    background-color: #e95095;
}
.featured-cats .chocolate-buttons h2 a {
    background-color: #937043;
}
.featured-cats h2 a:hover {
    background-color: #937043;
    color: #fff;
}
.quote-text .nested-container {
    background-color: #fcf0d0;
    padding: 25px;
}
@media only screen and (min-width: 960px) and (max-width: 1279px) {
    .featured-cats .grid12-2 {
        height: 300px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
    .featured-cats .grid12-2 {
        width: 48%;
    }
    .featured-cats .grid12-1 {
        display: none;
    }
    .featured-cats h2 a {
        font-size: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .featured-cats h2 a {
        width: 80%;
    }
    .featured-cats h2 a {
        font-size: 22px;
    }
}