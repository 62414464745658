/* Cart */

/*
Payment forms
*/
.page-type-cart dl.cc input.button {
    text-indent: 0;
}

.page-type-cart dl.cc dt {
    float: left;
    padding: 11px 10px 0 0;
    width: 200px;
    text-align: right;
}

.page-type-cart dl.cc dd {
    margin-left: 200px;
    padding-bottom: 5px;
}

.page-type-cart dl.cc{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 60px;
}

.page-type-cart dl.cc dd{
    clear: none !important;
}

.page-type-cart dd.amount,
.page-type-cart dt.amount{
    padding-top: 18px;
}

.page-type-cart dd.input-box .cc-date {
    width: 42%;
}

.page-type-cart dd.input-box.amount {
    font-size: 18px;
    font-weight: bold;
    padding: 11px 0 0;
}


.page-type-cart a.progress-change {
	display: none;
}

.page-type-cart .complete a.progress-change {
	display: inherit;
}

.page-type-cart #cart_progress_navi {
	display: none;
}

.page-type-cart input.quantity {
	max-width: 55px;
}


.page-type-cart .gmb-loader {
  font-size: 0;
  display: none;
  position: relative;
  height: 60px;
  width: 60px;
}
.page-type-cart .gmb-loader div {
  background: #666666;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  -webkit-animation: gmb-bounce 1.75s infinite;
  animation: gmb-bounce 1.75s infinite;
  top: 50%;
  margin-top: -20px;
}
.page-type-cart .gmb-loader div:nth-child(2) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  left: 50%;
  margin-left: -15px;
}
.page-type-cart .gmb-loader div:nth-child(3) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  right: 20px;
}

@-webkit-keyframes gmb-bounce {
  10% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    height: 10px;
  }

  14% {
    height: 20px;
  }

  15% {
    height: 10px;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
  }

  35% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gmb-bounce {
  10% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    height: 10px;
  }

  14% {
    height: 20px;
  }

  15% {
    height: 10px;
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 0.8, 1);
  }

  35% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.page-type-cart .table {
  display: block;
  text-align: center;
  margin-left: 10px;
  float: right;
}

.page-type-cart .table .cell {
  display: table-cell;
  vertical-align: middle;
}


.page-type-cart .divLoading {
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
	width: 300px;
}

.page-type-cart .divLoading span {
	position: relative;
	clear: both;
	display: block;
	margin-top: 10px;
	margin-left: -50px;
}

.page-type-cart .divLoader img {
	width: 50px;
	float: left;
	height: 50px;
	margin-bottom: 10px;
	background-color: #fff;
	border-radius: 40px;
}

.page-type-cart .rego-field-general {
	display: block;
	clear: both;
}

.page-type-cart #address1-clear {
	clear: both;
}

.page-type-cart .freeshipping {
	display: none;
}

.page-type-cart #opc-review .discount-form button {
	padding: 0 0;
	margin-left: 15px;
	margin-top: -5px;
}

.page-type-cart #opc-review .discount-form .input-box {
	float: left;
	width: 25%;
}

.page-type-cart #checkout-review-table-wrapper input:not(#promo):not([name="terms_conditions"]),
.page-type-cart #checkout-review-table-wrapper textarea {
	width: 70%;
}

.page-type-cart #checkout-review-table {
	margin-top: 25px;
}


.page-type-cart .order-review .discount {
	margin-bottom: 25px;
}

/* Buttons
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/
.page-type-cart a.button::-moz-focus-inner
{
    padding:0 !important;
    border:0 !important;
} /* FF Fix */
.page-type-cart a.button {
    -webkit-border-fit:lines; /* <- Safari & Google Chrome Fix */
    overflow:visible; width:auto; border:0; padding:0; margin:0; background:transparent; cursor:pointer;
    display: inline-block;
}
.page-type-cart a.button span {
    float:left;
    display:block;
    padding:0;
    font-size:12px;
    text-align:center;
    white-space:nowrap;
    background-color:#ddd;
    color:#000;

    transition:         color 300ms ease-in-out, background-color 300ms ease-in-out;
    -moz-transition:    color 300ms ease-in-out, background-color 300ms ease-in-out;
    -webkit-transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
    -o-transition:      color 300ms ease-in-out, background-color 300ms ease-in-out;
}
.page-type-cart a.button:hover span
{ background-color:#000; color:#fff; }
.page-type-cart a.button span span
{ padding:0 25px; height:45px; line-height:45px; }
.page-type-cart .xm-grid-cart button.button {
  margin: 5px 25px 5px 0;
}
/* Smaller button - for inline display with input boxes
-------------------------------------------------------------- */
.page-type-cart a.button.btn-inline span span { padding:0 10px !important; line-height:36px !important; height:36px !important; }

/* Auto-complete */
.page-type-cart .ui-autocomplete {
    height: auto !important;
    max-height: 180px !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding-bottom: 7px !important;
    text-align: left;
    width: 260px !important;
}

.page-type-cart .cart-table .product-name .cart-item-code {
    font-family: 'Montserrat',sans-serif !important;
    font-size: 12px !important;
}

/* terms and conditions */
.page-type-cart #checkout-review-table-wrapper .terms_conditions input {
	width: auto !important
}

.page-type-cart #checkout-review-table-wrapper .terms_conditions a {
	text-decoration: underline;
}

.page-type-cart .terms_conditions label {
	display: inline;
}

/*Configurator Styles*/
.page-type-cart .cart-table .configurator td{
    padding-bottom: 0px;
}
.page-type-cart .cart-table .configuratorSubItem h2{
    font-size: 14px;
}
.page-type-cart .cart-table .configuratorSubItem td{
    padding: 7px;
}

.page-type-cart .cart-table .configurator td,
.page-type-cart .cart-table .configuratorSubItem td{
    border-bottom: 1px solid #f5f5f5;
}

.page-type-cart .cart-table .configuratorSubItem.lastSubConfigurator td{
    padding-bottom: 12px;
    border-bottom: 1px solid #eee;
}

.page-type-cart .cart-table .item-row .product_comment.item-options dt {
    float: none;
}

.page-type-cart .cart-table .item-row .product_comment.item-options dd textarea {
    width: 100%;
}


#checkout-review-table dl dd {
	float: left;
	clear: both;
}

#checkout-progress-wrapper, #checkout-progress-wrapper .block-title, #checkout-progress-wrapper .block:first-child {
	padding-top: 0px;
	margin-top: 0px;
}
